import './AccountInfoModalComponent.css';
import { useRef } from 'react';
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg';
import { useAtom } from 'jotai';
import { atom_userData } from '../../infrastructure/jotai/store';

export const AccountInfoModalComponent = () => {

    const [userData, setUserData] = useAtom(atom_userData);   
    const closeButtonRef = useRef();

    const getCurrentAuthText = (id) => {
        switch (id) {
            case 0:
                return "Email/Password";
            case 1:
                return "Google";
            case 2:
                return "Apple";
        }
        return "";
    }


    return (
        <div className="modal account-info-modal fade" tabIndex="-1" id="account-info-modal" >
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-body">
                        <div className='title-modal mg-b-36'>
                            <button className='btn-gray-close mg-r-16' ref={closeButtonRef} data-bs-dismiss="modal"><CloseIcon /></button>
                            Account Info
                        </div>
                        <div>
                            <div className="account-info-item">
                                <div className="account-info-item-label">Email address</div>
                                <div className="account-info-item-value">{userData?.email}</div>
                            </div>
                            <div className="account-info-item">
                                <div className="account-info-item-label">Full Name</div>
                                <div className="account-info-item-value">{userData?.fullName}</div>
                            </div>
                            <div className="account-info-item">
                                <div className="account-info-item-label">Current Authentication</div>
                                <div className="account-info-item-value">{getCurrentAuthText(userData?.currentAuth)}</div>
                            </div>
                            <div className="account-info-item">
                                <div className="account-info-item-label">Has Google Auth?</div>
                                <div className="account-info-item-value">{userData?.hasGoogleAuth ? "Yes" : "No"}</div>
                            </div>
                            <div className="account-info-item">
                                <div className="account-info-item-label">Has Email/Password Auth</div>
                                <div className="account-info-item-value">{userData?.hasPassword ? "Yes" : "No"}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}