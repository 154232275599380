import { Suspense, React, useEffect } from "react";
import { useNavigation } from "react-router-dom";
import { useState } from "react";
import "./MainLayout.css";
import { modules } from "../infrastructure/helpers/module_pages";
import { Sidebar } from "../components/Common/Sidebar";
import { TankDropdownComponent } from "../components/Common/TankDropdownComponent";
import { ModuleLoading } from "../components/Common/ModuleLoading";
import { useAtom } from "jotai";
import { atom_devices, atom_currentTankId } from "../infrastructure/jotai/store";
import { UserDropDownComponent } from "../components/Common/UserDropdownComponent";

export const MainLayout = () => {
    const [currentDeviceId, setCurrentDeviceId] = useState();
    const [currentDeviceType, setCurrentDeviceType] = useState();
    const [currentDevicePanelScreenType, setCurrentDevicePanelScreenType] = useState();
    const [renderPage, setRenderPage] = useState();
    const [isTankChanged, setIsTankChanged] = useState(false);

    const [devices, ] = useAtom(atom_devices);
    const [currentTankId, setCurrentTankId ] = useAtom(atom_currentTankId);

    const navigation = useNavigation();

    useEffect(() => {
        let module = null;
        let page = null;

        if(isTankChanged){
            setCurrentDeviceId(null);
            setIsTankChanged(false);
        }

        if(currentDeviceId){
            module = modules.find(m => m.deviceType === currentDeviceType && m.devicePanelScreenType === currentDevicePanelScreenType);            
        } else {
            if(devices && currentTankId){
                const defaultDevice = devices
                                    .filter(x => x.tankId === currentTankId && x.screenVisible)
                                    .reduce((min, device) => device.orderIndex < min.orderIndex ? device : min);
                setCurrentDeviceId(defaultDevice.deviceId);
                setCurrentDeviceType(defaultDevice.type);
                setCurrentDevicePanelScreenType(defaultDevice.panelScreenType);
            }
        }

        if(module) {
            page = module.render(currentDeviceId);
            setRenderPage(page);
        }
    }, [currentDeviceId, currentDeviceType, currentDevicePanelScreenType, devices, currentTankId, isTankChanged]);

    return (
        <div className="main-layout">
            <main className="container mg-t-36 mg-b-36">
                { navigation.state === "loading" &&  <ModuleLoading /> }
                <div className="row py-3">
                    <div className="d-flex justify-content-between">
                        <div>
                            <TankDropdownComponent currentTankId={currentTankId} setCurrentTankId={setCurrentTankId} setIsTankChanged={setIsTankChanged}/>
                        </div>
                        <div>
                            <UserDropDownComponent />
                        </div>                        
                    </div>
                </div>
                <div className="row">
                    <div className="col-6">
                        <Sidebar                             
                            currentDeviceType={currentDeviceType}
                            setCurrentDeviceType={setCurrentDeviceType}
                            currentDevicePanelScreenType={currentDevicePanelScreenType}
                            setCurrentDevicePanelScreenType={setCurrentDevicePanelScreenType}
                            currentDeviceId={currentDeviceId}
                            setCurrentDeviceId={setCurrentDeviceId}
                        />
                    </div>
                    <div className="col-6">
                        <div className="module-container">
                            <Suspense fallback={<ModuleLoading />}>
                                {renderPage}
                            </Suspense>
                        </div>                        
                    </div>                    
                </div>                
            </main>
        </div>
    );
}