import { useContext } from "react";
import { Context } from "../contexts/AuthContext";

import Login from "../pages/auth/Login";

const Protected = ({children}) => {
    const { user } = useContext(Context);

    if (!user) {
        return <Login />;
    }

    return children;
}

export default Protected;