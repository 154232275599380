import './Login.css';

import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

import { ReactComponent as Logo } from '../../assets/icons/logo.svg';
import { ReactComponent as GoogleIcon } from '../../assets/icons/google-logo.svg';
import { ReactComponent as AppleIcon } from '../../assets/icons/apple-logo.svg';

import { signInWithEmailAndPassword } from "firebase/auth";
import { auth, signInWithApplePopup, signInWithGooglePopup } from "../../infrastructure/helpers/firebase.utils";

const Login = () => {

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const onSignInClick = () => {
        signInWithEmailAndPassword(auth, email, password)
            .then(() => {
                // navigate("/");
            }).catch(err => {

            });
    }

    const onGoogleSignIn = () => {
        signInWithGooglePopup().then(x => {
            // navigate("/");
        }).catch(err => {

        });
    }

    const onAppleSignIn = () => {
        signInWithApplePopup().then(x => {
            // navigate("/");
        }).catch(err => {
            
        });
    }

    return (
        <div className="login-main">
            <div>
                <Logo />
                <div className='login-container'>
                    <h2 className='login-title'>Sign in</h2>
                    <TextField
                        id="email"
                        label="Username or email"
                        variant="outlined"
                        fullWidth
                        defaultValue={email}
                        className='text-input'
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        id="password"
                        label="Password"
                        variant="outlined"
                        type="password"
                        fullWidth
                        defaultValue={password}
                        className='text-input'
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <button className='btn-blue btn-sign-in' onClick={onSignInClick}>Sign in</button>
                    <a href="/forgot-password" className='login-links'>Forgot your password?</a>
                    <div className='separator'><span>or</span></div>
                    <button className='btn-white btn-social' onClick={onGoogleSignIn}>
                        <GoogleIcon />
                        <span>Sign in with Google</span>
                    </button>
                    <button className='btn-white btn-social' onClick={onAppleSignIn}>
                        <AppleIcon />
                        <span>Sign in with Apple</span>
                    </button>
                </div>
            </div>
            <div>
                <p className='login-subtitle'>Already have an account? <a className='login-links' href="/register">Sign up</a></p>                
            </div>
        </div>
    );
}

export default Login;