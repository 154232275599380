import Moment from "react-moment";

import { CurrentTimeComponent } from "../Inputs/CurrentTimeComponent";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";

import { ReactComponent as RectangleFill } from "../../assets/icons/sidebar/sidebar-rectangle-fill.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { getTimeZoneDescriptionTranslated } from "../../infrastructure/helpers/time_helper";
import { atom_timeZonesList } from "../../infrastructure/jotai/store";

export const Widget = (props) => {
    const { icon, title, details, isActive, iconContainerColor, module } = props;
    return(
        <div className="widget-container" data-is_active={isActive} to={module}>
            <h5 className="main-widget-title-container">
                <span className="d-flex">
                    <span className={"icon-container icon-container-" + iconContainerColor}>
                        {icon}
                    </span>
                    <span className="main-widget-title">
                        {title}
                    </span>
                </span>
                {isActive && <FontAwesomeIcon icon={faChevronRight} className="main-width-chevron"/>}                
            </h5>
            <div className="widget-details">
                {details}
            </div>
        </div>
    )
}

export const TemperatureModuleDetails = (props) => {
    const { temperature } = props;
    return (
        <p>{(temperature / 10.0).toFixed(1)} °C</p>
    )
}

export const PhModuleDetails = (props) => {
    const { value } = props;

    return (
        <p>{(value / 100).toFixed(1)}</p>
    )
}

export const EcModuleDetails = (props) => {
    const { value } = props;

    return (
        <p>{value} <span className="sidebar-detail-text">us/cm</span></p>
    )
}

export const TdsModuleDetails = (props) => {
    const { value } = props;

    return (
        <p>{value} <span className="sidebar-detail-text">ppm</span></p>
    )
}

export const SgModuleDetails = (props) => {
    const { value } = props;

    return (
        <p>{(value / 1000).toFixed(3).toString().replace(/^0+/, '')}</p>
    )
}

export const LightDetails = (props) => {
    const { lightPercentage } = props;
    return (
        <p>{lightPercentage}%</p>
    )
}

export const SocketDetails = (props) => {
    const { deviceValue } = props;
    let socketStatuses = [
        deviceValue.socket1On,
        deviceValue.socket2On,
        deviceValue.socket3On,
        deviceValue.socket4On,
    ]

    return (
        <div className="socket-status-container">
            {socketStatuses.map((item, index) => (
                <div key={index} className={"socket-status-column " + (item ? "" : "socket-off")}>
                    <span className="text-on-off">{item ? "On" : "Off"}</span>
                    <span className="rectangle-icon-container"><RectangleFill/></span>
                </div>
            ))}
        </div>
    )
}

export const FeederAndPumpDetails = (props) => {
    const { deviceId, feederPumpLastActions } = props;
    const lastAction = feederPumpLastActions.find(x => x.id === deviceId);

    return (
        <p className="sidebar-detail-text"><Moment fromNow>{lastAction.timeStamp}</Moment></p>
    )
}

export const TdsDetails = (props) => {
    const { tdsVolume } = props;
    return (
        <p>{tdsVolume} <span className="tdsDetails-ppm">ppm</span></p>
    )
}

export const CoolerControllerDetails = (props) => {
    const { deviceValue } = props;
    return (
        <p>{deviceValue ? 'On' : 'Idle'}</p>
    )
}

export const RtcDetails = (props) => {
    const { isOnline, tank, currentLanguage } = props;

    const [timeZone, setTimeZone] = useState("");
    const [timeZonesList,] = useAtom(atom_timeZonesList);

    useEffect(() => {
        let tz = getTimeZoneDescriptionTranslated(currentLanguage, tank?.timeZone, timeZonesList);
        
        if (tz && tz.length > 40) {
            tz = tz.substring(0, 35) + "...";
        }
        setTimeZone(tz);
    }, [currentLanguage, tank, timeZonesList]);

    return (
        <div className="between-row">
            <p className="start-column sidebar-detail-text text-muted">
                <CurrentTimeComponent tz={tank?.timeZone}/>
                {timeZone}
            </p>
            <p className="d-inline-block">{isOnline ? 'Online' : ''}</p>
        </div>
    )
}