import { ReactComponent as TermometerIcon } from "../../assets/icons/sidebar/sidebar-termometer.svg";
import { ReactComponent as LightIcon } from "../../assets/icons/sidebar/sidebar-light.svg";
import { ReactComponent as RelaySocketIcon } from "../../assets/icons/sidebar/sidebar-relay-socket.svg";
import { ReactComponent as FeederIcon } from "../../assets/icons/sidebar/sidebar-feeder.svg";
import { ReactComponent as DropletIcon } from "../../assets/icons/sidebar/sidebar-droplet.svg";
import { ReactComponent as WaterPumpIcon } from "../../assets/icons/sidebar/water-pump.svg";
import { ReactComponent as ClockOutlineIcon } from "../../assets/icons/sidebar/clock-outline.svg";
import { ReactComponent as FanIcon } from "../../assets/icons/sidebar/fan.svg";

import { deviceTypeEnum, devicePanelScreenTypesEnum } from "../../infrastructure/helpers/device_types";

import { TemperatureModuleDetails, 
    PhModuleDetails, 
    EcModuleDetails, 
    TdsModuleDetails, 
    SgModuleDetails, 
    LightDetails, 
    SocketDetails, 
    FeederAndPumpDetails, 
    CoolerControllerDetails,
    RtcDetails } from "../../components/Common/SidebarWidgetDetailComponent";

const getDeviceIcon = (device) => {
    switch(device.type){
        case deviceTypeEnum.LIGHTS:
        case deviceTypeEnum.LIGHTS_RGB:
        case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
            return <LightIcon />;
        case deviceTypeEnum.POWER_STRIP_3_SOCKET:
        case deviceTypeEnum.POWER_STRIP_4_SOCKET:
        case deviceTypeEnum.POWER_STRIP_6_SOCKET:
            return <RelaySocketIcon />;
        case deviceTypeEnum.FEEDER:
            return <FeederIcon />;
        case deviceTypeEnum.DOSING_PUMP:
            return <WaterPumpIcon />;
        case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
            switch(device.panelScreenType){
                case devicePanelScreenTypesEnum.ec:
                case devicePanelScreenTypesEnum.ph:
                case devicePanelScreenTypesEnum.tds:
                case devicePanelScreenTypesEnum.sg:
                    return <DropletIcon />;
                case devicePanelScreenTypesEnum.temperature:
                    return <TermometerIcon />;
                default:
                    return <WaterPumpIcon style={{height: '40px', width: '40px'}}/>;
            }
        case deviceTypeEnum.RTC:
            return <ClockOutlineIcon />;
        case deviceTypeEnum.COOLER_CONTROLLER:
            return <FanIcon />;
        default:
            return null;
    }
}

const getDeviceDetails = (device, deviceValues, feederPumpLastActions, tanks, currentTankId, isOnline, currentLanguage) => {
    if(!deviceValues) return null;
    const deviceValue = deviceValues.find(dv => dv.id === device.deviceId);
    if(!deviceValue) return null;
    
    switch(device.type){
        case deviceTypeEnum.LIGHTS:
        case deviceTypeEnum.LIGHTS_RGB:
        case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
            return <LightDetails lightPercentage={deviceValue.currentLightsPercentage} />;
        case deviceTypeEnum.POWER_STRIP_3_SOCKET:
        case deviceTypeEnum.POWER_STRIP_4_SOCKET:
        case deviceTypeEnum.POWER_STRIP_6_SOCKET:
            return <SocketDetails deviceValue={deviceValue} />;
        case deviceTypeEnum.FEEDER:
        case deviceTypeEnum.DOSING_PUMP:
            return <FeederAndPumpDetails deviceId={device.deviceId} feederPumpLastActions={feederPumpLastActions}/>;
        case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
            switch(device.panelScreenType){
                case devicePanelScreenTypesEnum.temperature:
                    return <TemperatureModuleDetails temperature={deviceValue.temperature} />;
                case devicePanelScreenTypesEnum.ph:
                    return <PhModuleDetails value={deviceValue.ph} />;
                case devicePanelScreenTypesEnum.ec:
                    return <EcModuleDetails value={deviceValue.ec} />;
                case devicePanelScreenTypesEnum.tds:
                    return <TdsModuleDetails value={deviceValue.tds} />;
                case devicePanelScreenTypesEnum.sg:
                    return <SgModuleDetails value={deviceValue.sg} />;
                default:
                    return null;
            }
        case deviceTypeEnum.COOLER_CONTROLLER:
            return <CoolerControllerDetails deviceValue={deviceValue.on} />;
        case deviceTypeEnum.RTC:
            let currentTank = tanks.find(x => x.id === currentTankId);
            
            return <RtcDetails isOnline={isOnline} tank={currentTank} currentLanguage={currentLanguage}/>;
        default: 
            return null;
    }
}

const getIconColor = (deviceType, devicePanelScreenType) => {
    switch (deviceType) {
        case deviceTypeEnum.WATER_QUALITY_5IN1:
        case deviceTypeEnum.WATER_QUALITY_8IN1:
        case deviceTypeEnum.WATER_QUALITY_PANEL_ITEM:
            if(devicePanelScreenType === devicePanelScreenTypesEnum.temperature){
                return "red";
            } else {
                return "light-blue";
            }                
        case deviceTypeEnum.LIGHTS:
        case deviceTypeEnum.LIGHTS_RGB:
        case deviceTypeEnum.LED_CONTROLLER_1_CHANNEL:
            return "yellow";
        case deviceTypeEnum.POWER_STRIP_3_SOCKET:
        case deviceTypeEnum.POWER_STRIP_4_SOCKET:
        case deviceTypeEnum.POWER_STRIP_6_SOCKET:
            return "purple";
        case deviceTypeEnum.FEEDER:
            return "light-green";
        case deviceTypeEnum.DOSING_PUMP:
            return "orange water-drop-icon-container";
        case deviceTypeEnum.COOLER_CONTROLLER:
            return "blue cooler-icon-container";
        case deviceTypeEnum.RTC:
            return "gray rtc-icon-container";
        default:
            break;
    }
};

const isActive = (device, currentDeviceId, currentDeviceType, currentDevicePanelScreenType) => {
    return device.deviceId === currentDeviceId && device.type === currentDeviceType && device.panelScreenType === currentDevicePanelScreenType;
}

export { getDeviceIcon, getDeviceDetails, getIconColor, isActive };