import React from "react";
import { MainLayout } from "./layouts/MainLayout";
import Protected from "./routes/Protected";

import { NotificationProvider } from './contexts/NotificationContext';

import AuthContext from "./contexts/AuthContext";
import './infrastructure/helpers/firebase.utils';

import { createBrowserRouter, RouterProvider } from "react-router-dom";

const LightsModule = React.lazy(() => import('./pages/LightsModule'));

function App() {

  const router = createBrowserRouter([
    {path: "/",
      element: <Protected><MainLayout /></Protected>
    }
  ]);

  return (
    <AuthContext>
      <NotificationProvider>
        <RouterProvider router={router} />
      </NotificationProvider>
    </AuthContext>
  );
}

export default App;
